import { Box, Flex, FlexProps, Heading, Icon, Text } from '@chakra-ui/react'
import React, { useCallback } from 'react'
import { MdChevronRight } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

export type CardButtonContainerProps = FlexProps & {
  background: string
  to?: string
  onClick?: () => void
  children?: React.ReactNode
}

export const CardButtonContainer: React.FC<CardButtonContainerProps> = ({
  background,
  to,
  onClick,
  children,
  ...props
}) => {
  const navigate = useNavigate()

  const handleClick = useCallback((): void => {
    onClick?.()
    if (!to) return
    navigate(to)
  }, [navigate, onClick, to])

  return (
    <Flex
      boxShadow="lg"
      cursor="pointer"
      overflow="hidden"
      maxW="300px"
      alignItems="center"
      flexDirection="column"
      border="3px solid"
      borderColor="gray.300"
      borderRadius={8}
      bg={background}
      onClick={handleClick}
      {...props}
    >
      <Flex
        flexDirection="column"
        align="center"
        transition="all 200ms ease-in-out"
        _hover={{
          backgroundColor: 'rgba(0, 0, 0, 0.2);',
        }}
      >
        {children}
      </Flex>
    </Flex>
  )
}

type CardButtonFooterProps = FlexProps & {
  header: React.ReactElement | string
  description: React.ReactElement | string
}

export const CardButtonFooter: React.FC<CardButtonFooterProps> = ({
  header,
  description,
  ...props
}) => (
  <Flex
    backdropBlur={2}
    backgroundColor="rgba(255, 255, 255, 0.7)"
    borderTop="2px solid"
    borderColor="white"
    px={6}
    py={4}
    gap={1}
    flexDirection="column"
    {...props}
  >
    <Flex wrap="nowrap" alignItems="center">
      <Heading fontSize="2xl" noOfLines={1} wordBreak="break-all">
        {header}
      </Heading>
      <Icon as={MdChevronRight} boxSize={6} />
    </Flex>
    <Text fontSize="lg" color="gray.600" lineHeight="20px">
      {description}
    </Text>
  </Flex>
)

export type CardButtonProps = {
  background: string
  header: React.ReactElement | string
  description: React.ReactElement | string
  icon: React.ComponentType
  to: string
  onClick?: () => void
}

export const CardButton: React.FC<CardButtonProps> = ({
  icon,
  background,
  header,
  description,
  to,
  onClick,
}) => {
  return (
    <CardButtonContainer background={background} to={to} onClick={onClick}>
      <Box py={16}>
        <Icon color="white" boxSize={16} as={icon} />
      </Box>
      <CardButtonFooter header={header} description={description} />
    </CardButtonContainer>
  )
}
