import analytics from '@capturi/analytics'
import { usePageTitle } from '@capturi/react-utils'
import { PageHeading } from '@capturi/ui-components'
import { Flex, Text } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'
import { IoMdPie } from 'react-icons/io'
import { MdTimer } from 'react-icons/md'

import { CardButton } from '../components/CardButton'
import { Event } from '../events'
import useTrendsRoutes from '../routes'

const logEvent = (event: Event): void => {
  analytics.event(event)
}

const TrendTypePickerPage: React.FC = () => {
  usePageTitle(t`Trends`)
  const trendsRoutes = useTrendsRoutes()

  return (
    <>
      <PageHeading w="fit-content" data-stonly="trends_header">
        <Trans>Trends</Trans>
      </PageHeading>
      <Text color="textMuted">
        <Trans>Choose the type of trend report</Trans>
      </Text>
      <Flex
        width="100%"
        mx="auto"
        justify="center"
        align="center"
        direction={{ base: 'column', lg: 'row' }}
        mt="14vh"
        gap="clamp(32px, 3vw, 48px)"
      >
        <CardButton
          background="workspace.sidebar.darkbg"
          data-stonly="live-trend-card"
          icon={MdTimer}
          header={<Trans>Live trend calculation</Trans>}
          description={
            <Trans>Trend reports on demand for up to 400 conversations</Trans>
          }
          to={trendsRoutes.liveTrends()}
          onClick={() => {
            logEvent('trend-type--live-selected')
          }}
        />
        <CardButton
          background="primaryGradient"
          icon={IoMdPie}
          header={<Trans>Segment trend report</Trans>}
          description={<Trans>Precalculated trend reports for segments</Trans>}
          to={trendsRoutes.segmentTrends()}
          onClick={() => {
            logEvent('trend-type--segment-selected')
          }}
        />
      </Flex>
    </>
  )
}

export default TrendTypePickerPage
